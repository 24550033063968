
:root{
    --p1-bg: url("./assets/images/bg_1920X1080.png");
}

.p1{
    height: 100vh; //var(--height);
    position: relative;

    &__video{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    &__footer{
        &-wrapper{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            &_show{
                opacity: 1;
            }
        }
        display: flex;
        padding-bottom: var(--db-pb, calc(38px * var(--scale)));
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--db-gap, 46px);
        transition: opacity .3s;
    }


    &__icons{
        display: flex;
        align-items: center;
        gap: var(--db-i-g, calc(18px * var(--scale)));
    }

    &__icon{
        width: var(--db-icon-w, calc(46px * var(--scale)));
        height: var(--db-icon-h, calc(46px * var(--scale)));
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        &_steam{
            background-image: url('./assets/images/icon/steam.png');
            &:hover{
                background-image: url('./assets/images/icon/steam_hover.png');
            }
        }

        &_x{
            background-image: url('./assets/images/icon/x.png');
            &:hover{
                background-image: url('./assets/images/icon/x_hover.png');
            }
        }

        &_mail{
            background-image: url('./assets/images/icon/mail.png');
        }

        &_iwatch{
            background-image: url('./assets/images/icon/iwatch.png');
            &:hover{
                background-image: url('./assets/images/icon/iwatch_hover.png');
            }
        }

        &_switch{
            background-image: url('./assets/images/icon/switch.png');
            cursor: unset;
        }
    }

}

.zck{
    display: flex;
    padding-left: var(--zck-pl, calc(42px * var(--scale)));
    align-items: center;
    gap: var(--zck-gap, 16px);
    width: calc(648px * var(--scale));
    box-sizing: border-box;

    // border-image: linear-gradient(175deg, rgba(186, 160, 173, 1), rgba(255, 255, 255, 1)) 1 1;
    border-radius: var(--zck-r, calc(132px * var(--scale)));
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.16);

    // overflow: hidden;
    position: relative;

    opacity: 0;
    &_show{
        opacity: 1;
    }    
    transition: opacity .3s;

    &__input{
        flex: 1;
        display: flex;
        // padding-bottom: var(--p3-t-gap, calc(4px * var(--scale)));
        align-items: center;
        gap: var(--zck-inputw-p-r, calc(12px * var(--scale)));
        transition: transform .3s, opacity .3s;
        opacity: 1;
        &_hidden{
            opacity: 0;
        }

        &__country{
            &-wrapper{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // border-bottom: 1px solid white;
                box-sizing: border-box;
                // display: none;
                opacity: 1;
                transition: opacity .3s;

                cursor: pointer;
                // &__inputing{
                //     display: block;
                // }
            }

            display: flex;
            padding: 0px var(--zck-input-p-r, calc(14px * var(--scale)));
            justify-content: center;
            align-items: center;     
            
            color: #FFF;
            font-family: "Afacad Flux";
            font-size: calc(24px * var(--scale));
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        &__text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            &-wrapper{
                display: flex;
                // width: 100%;
                padding: 0px var(--zck-inputw-p-b, calc(16px * var(--scale))) 0 0px;
                flex-direction: column;
                align-items: flex-start;

                // border-bottom: 1px solid white;
                box-sizing: border-box;

                &__inputing{
                    // display: none;
                }
            }
    
            width: 100%;
            outline: none;
            background: none;
            border: 0px;
    
            display: flex;
            // padding: 0px var(--zck-input-p-r, calc(10px * var(--scale))) 0px var(--zck-input-p-l, calc(6px * var(--scale)));
            justify-content: center;
            align-items: center;
            align-self: stretch;
    
            // border-bottom: 1px solid #FFF;
    
            color:#FFF;
            font-family: "Afacad Flux";
            font-size: var(--zck-font, calc(24px * var(--scale)));
            // font-size: calc(24px * var(--scale));
            font-style: normal;
            font-weight: 300;
            line-height: normal;
    
            &::placeholder{
                color: rgba(255, 255, 255, 0.50);
            }
        }
        
    }

    &__block{
        width: calc(182px * var(--scale));
        height: calc(64px * var(--scale));
    }

    &__btn{
        &-wrapper{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            max-width: calc(260px * var(--scale));
            transition: max-width .3s;
            text-align: center;
        }
        padding: 0 calc(7px * var(--scale));
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: calc(64px * var(--scale));
        box-sizing: border-box;

        cursor: pointer;

        border-radius: var(--zck-r, calc(50px * var(--scale)));
        background: linear-gradient(120deg, rgba(219, 210, 220, 0.80) -2.29%, rgba(176, 128, 175, 0.80) 60.1%);

        color: #FFF;
        font-family: "Afacad Flux";
        font-size: calc(22px * var(--scale));
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
}

.region-selection{
    position: relative;
    display: inline-block;
    padding-top: 2px;
    &__ul{
        display: flex;
        width: calc(366px * var(--scale));
        padding: calc(10px * var(--scale)) 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: calc(2px * var(--scale));

        opacity: 0.8;
        background: linear-gradient(90deg, #D9D0DA 0%, #AE80AD 100%);

        overflow-y: scroll;
        scrollbar-width: none;
        height: calc(256px * var(--scale));
        box-sizing: border-box;


        &-wrapper{
            position: absolute;
            left: 0;
            bottom: 0;
            // max-width: calc(441px * var(--scale));
            // opacity: 1;
            overflow: hidden;
            &_hidden{
                max-width: 0;
                // opacity: 0;
            }
        }
    }

    &__li{
        display: flex;
        padding: 0px calc(20px * var(--scale));
        align-items: center;
        gap: calc(20px * var(--scale));
        align-self: stretch;

        box-sizing: border-box;
        cursor: pointer;

        color: #FFF;
        // font-family: "Afacad Flux";
        font-size: calc(20px * var(--scale));
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        &_select{
            font-weight: 600;
        }

        &_select,
        &:hover{
            background: rgba(255, 255, 255, 0.20);
        }

        &__phone{
            width: calc(72px * var(--scale));
        }

        &__country{
            flex: 1;   
        }
    }
}

.btn-fr {
    font-size: calc(26px * var(--scale));
}
.btn-ru {
    font-size: calc(22px * var(--scale));
}

.input-ru {
    font-size: var(--zck-font, calc(18px * var(--scale)));
}
.input-pt {
    font-size: var(--zck-font, calc(18px * var(--scale)));
}
.input-es {
    font-size: var(--zck-font, calc(18px * var(--scale)));
}
.input-fr {
    font-size: var(--zck-font, calc(20px * var(--scale)));
}
.input-en {
    //font-size: var(--zck-font, calc(18px* var(--scale)));
    &::placeholder{
        font-size: var(--zck-font, calc(23px * var(--scale)));
    }
}

// video::-webkit-media-controls {
//     display: none !important;
// }
// video::-media-controls {
//     display: none !important;
// }
video::-webkit-media-controls-fullscreen-button {
    display: none;
}

video::-webkit-media-controls-play-button {
    display: none;
}

video::-webkit-media-controls-timeline {
    display: none;
}

video::-webkit-media-controls-current-time-display {
    display: none;
}

video::-webkit-media-controls-time-remaining-display {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    display: none;
}

video::-webkit-media-controls-volume-slider {
    display: none;
}