html{
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Afacad Flux";
  
}

:root{
  --scale: 1;
  --height: 1080px;
  --ani-delay: .3s
}

@media only screen and (width > 2560px) {
  :root{
    --scale: 2;
    --height: 2160px
  }
  
}

@media only screen and (width <= 2560px) {
  :root{
      --scale: 1.33;
      --height: 1440px;
  }
  
}


@media only screen and (width <= 1920px) {
  :root{
      --scale: 1;
      --height: 1080px;
  }
}

@media only screen and (width <= 1504px) {
  :root{
    --scale: 0.82;
    --height: 846px;
    
}
  
}

@media only screen and (width <= 1152px) {
  :root{
      --scale: 0.82;
      --height: 846px;
      
  }
}
