@import '../functions';

.header{
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    left: 0;
    top: 0;
    &-grid{
        display: grid;
        height: px2vw(94);
        grid-template-columns:  1fr 1fr 1fr;
        padding: 0px px2vw(24) 0px px2vw(10);
        &-left{
            display: flex;
            align-items: center;
            gap: px2vw(14);
            justify-content: flex-start;
            &-goback{
                width: px2vw(50);
                height: px2vw(50);
            }
        }
        &-center {
            justify-content: center;
            align-items: center;
            display: inline-flex;
            &-logo {
                width: px2vw(136);
                height: px2vw(44);
            }
        }
        &-right {
            display: flex;
            align-items: center;
            gap: px2vw(14);
            justify-content: flex-end;
            &-language, &-sound{
                width: px2vw(50);
                height: px2vw(50);
            }
        }
    }
}

.language-selection{
    position: relative;
    display: inline-block;

    &__parent {
        display: flex;
        height: 100%;
    }
    &__ul{

        display: flex;
        width: px2vw(308);
        padding: px2vw(32) 0 px2vw(32) 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: px2vw(22);

        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(219, 210, 220, 0.80) 0%, rgba(176, 128, 175, 0.80) 100%);

        &-wrapper{
            position: absolute;
            right: 0;
            top: 0;
            // max-width: calc(441px * var(--scale));
            // opacity: 1;
            
            &_hidden{
                max-width: 0;
                // opacity: 0;
            }
            
        }
    }

    &__li{
        width: 100%;
        color: #FFF;
        text-align: center;
        font-family: "Afacad Flux";
        font-size: calc(26px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        box-sizing: border-box;
        cursor: pointer;

        &_select{
            font-weight: 800;
        }

        &:hover{
            background: rgba(255, 255, 255, 0.20);
        }
    }
}