
@import '../functions';

.footer{
    display: flex;
    padding: 0px px2vw(40) px2vw(88) px2vw(40);
    flex-direction: column;
    align-items: center;
    gap: px2vw(28);
    align-self: stretch;

    &-logo {
        display: flex;
        padding: px2vw(70) px2vw(10) px2vw(32) px2vw(10);
        flex-direction: column;
        align-items: center;
        gap: px2vw(10);
        align-self: stretch;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        img {
            width: px2vw(106);
            height: px2vw(34);
        }
    }

    &-2 {
        display: flex;
        padding: px2vw(30) 0px px2vw(14) 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: px2vw(16);
        align-self: stretch;
        &-text {
            align-self: stretch;
            color: #000;
            text-align: center;
            font-family: "Afacad Flux";
            font-size: calc(20px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
        &-icons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: px2vw(24);
            align-self: stretch;
            &-item {
                width: px2vw(60);
                height: px2vw(60);
            }
        }
    }

    &-desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: px2vw(6);
        align-self: stretch;
        &-title {
            align-self: stretch;
            color: #000;
            text-align: center;
            font-family: "Afacad Flux";
            font-size: calc(20px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        &-content {
            width: px2vw(554);
            color: #000;
            text-align: center;
            font-family: "Afacad Flux";
            font-size: calc(20px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 300;
            line-height: px2vw(22); /* 110% */
        }
    }

    &-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: px2vw(30);
        align-self: stretch;
        &-panel {
            display: flex;
            width: px2vw(366);
            height: px2vw(87);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-follow {
                align-self: stretch;
                color: #000;
                text-align: center;
                font-family: "Afacad Flux";
                font-size: calc(20px * var(--fontScale, .5));
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            &-items {
                display: flex;
                align-items: center;
                gap: px2vw(18);
                align-self: stretch;
                &-icon {
                    width: px2vw(60);
                    height: px2vw(60);
                }
                &-email {
                    display: flex;
                    width: px2vw(288);
                    flex-direction: row;
                    align-items: flex-start;
                    align-self: stretch;
                    align-items: center;
                    gap: px2vw(30);
                    align-self: stretch;
                    position: relative;
                    &-input {
                        flex: 1 0 0;
                        color: #000;
                        font-family: "Afacad Flux";
                        font-size: calc(18px * var(--fontScale, .5));
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        border: none;
                        border-bottom: 1px solid;
                        outline: none;
                    }
                    &-btn {
                        display: flex;
                        height: px2vw(20);
                        min-width: px2vw(36);
                        padding: px2vw(2) px2vw(8);
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        border-radius: 2px;
                        border: 0.552px solid #000;
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }

        &-pp {
            align-self: stretch;
            color: #000;
            text-align: center;
            font-family: "Afacad Flux";
            font-size: calc(24px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }
}

.follow {
    display: grid;
    width: px2vw(288);
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    &__input {
        &-wrapper {
            display: flex;
            align-items: center;
            gap: px2vw(30);
            align-self: stretch;
            border-bottom: 0.6px solid #000;
        }
        flex: 1 0 0;
        color: #000;
        font-family: "Afacad Flux";
        font-size: calc(18px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border: 0px;
        outline: none;
        width: 80%;
        padding-right: 20%;
    }
    &__btn {
        &-wrapper {
            display: flex;
            height: px2vw(20);
            min-width: px2vw(36);
            padding: px2vw(2) px2vw(8);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            border-radius: 2px;
            border: 0.552px solid #000;
            position: absolute;
            right: 0;
        }
        align-self: stretch;
        color: #000;
        text-align: center;
        font-family: "Afacad Flux";
        font-size: calc(14px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

.followed {
    display: flex;
    width: px2vw(288);
    height: px2vw(24);
    padding: 0 px2vw(10);
    justify-content: center;
    align-items: center;
    gap: px2vw(10);
    border-radius: 4px;
    border: 0.6px solid #000;
    &__text {
        color: #000;
        font-family: "Afacad Flux";
        font-size: calc(14px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}