.header{
    &-wrapper{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 10;
        
    }
    display: flex;
    height: var(--tb-h, calc(66px * var(--scale)));
    padding: 0px var(--tb-pr, calc(22px * var(--scale)));
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid rgba($color: #000000, $alpha: .2);
    box-sizing: border-box;
    transition: border-color .6s, background-color .6s, opacity .6s;
    opacity: 1;
    // display: ;
    &_hidden{
        opacity: 0;
    }
    &_white{
        background: white;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0);
    }
    &_white{
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }

    

    &__goback{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        align-items: center;
        display: flex;
        padding: 0px var(--tb-pr, calc(22px * var(--scale)));
        &__img{
            &-wrapper{
                height: 100%;
                width: 42px;
                display: flex;
                align-items: center;
            }
            width: 42px;
            height: 42px;
            cursor: pointer;
        }
    }

    &__logo{
        width: var(--tb-logo-w, calc(116px * var(--scale)));
        height: var(--tb-logo-h, calc(38px * var(--scale)));
        cursor: pointer;
        z-index: 20;
    }

    &__btns{

        &-wrapper{
            position: absolute;
            left: 0;
            right: 0;
            // justify-self: flex-end;
            padding: 0px var(--tb-pr, calc(22px * var(--scale)));
            
        }

        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: var(--spacing-175, calc(14px * var(--scale)));

        &_language,
        &_sound{
            width: var(--tb-icon, calc(42px * var(--scale)));
            height: var(--tb-icon, calc(42px * var(--scale)));

            &-wrapper{
                display: inline-block;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }


    }
}

.language-selection{
    position: relative;
    display: inline-block;
    &__ul{

        display: flex;
        width: calc(208px * var(--scale));
        padding: var(--yy-p-t, calc(32px * var(--scale))) 0 var(--yy-p-b, calc(32px * var(--scale))) 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--yy-gap, calc(8px * var(--scale)));

        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(219, 210, 220, 0.80) 0%, rgba(176, 128, 175, 0.80) 100%);

        &-wrapper{
            position: absolute;
            right: 0;
            top: 0;
            // max-width: calc(441px * var(--scale));
            // opacity: 1;
            
            &_hidden{
                max-width: 0;
                // opacity: 0;
            }
            
        }
    }

    &__li{
        width: 100%;
        color: #FFF;
        text-align: center;
        font-family: "Afacad Flux";
        font-size: var(--yy-font, calc(20px * var(--scale)));
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        box-sizing: border-box;
        cursor: pointer;

        &_select{
            font-weight: 800;
        }

        &:hover{
            background: rgba(255, 255, 255, 0.20);
        }
    }
}