



.footer{
    display: flex;
    padding: var(--p9-t-m, calc(76px * var(--scale))) var(--p9-r-g, calc(154px * var(--scale))) var(--p9-db-m, calc(88px * var(--scale))) var(--p9-l-g, calc(154px * var(--scale)));
    justify-content: center;
    align-items: center;
    gap: var(--p9-m-g, calc(138px * var(--scale)));
    background: #FFF;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.20);
    &__logo {
        display: flex;
        width: calc(682px * var(--scale));
        height: calc(276px * var(--scale));
        justify-content: center;
        align-items: center;
        
        &-img {
            width: calc(190px * var(--scale));
            height: calc(62px * var(--scale));
            // position: absolute;
            left: calc(246px * var(--scale));
            bottom: calc(106px * var(--scale));
            // background-image: url('./assets/images/logo.png');
            // background-repeat: no-repeat;
            // background-size: 100%;
        }
    }
    &__content {
        display: flex;
        width: calc(792px * var(--scale));
        align-items: flex-start;
        gap: var(--p9-r-l-g, calc(24px * var(--scale)));
        &-left {
            display: flex;
            height: calc(276px * var(--scale));
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1 0 0;
            &-icons {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--zck-font, calc(30px * var(--scale)));
                align-self: stretch;
                &-text {
                    align-self: stretch;
                    color: var(--base-black, #000);
                    font-family: "Afacad Flux";
                    font-size: calc(23px * var(--scale));
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
                &-item {
                    display: flex;
                    align-items: center;
                    gap: var(--p9-r-l-g, calc(24px * var(--scale)));
                    align-self: stretch;
                    &-steam{
                        width: var(--db-icon-w, calc(46px * var(--scale)));
                        height: var(--db-icon-h, calc(46px * var(--scale)));
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        cursor: pointer;
                        background-image: url('./assets/images/steam.png');
                        &:hover{
                            background-image: url('./assets/images/steam_hover.png');
                        }
                    }

                    &-x{
                        width: var(--db-icon-w, calc(46px * var(--scale)));
                        height: var(--db-icon-h, calc(46px * var(--scale)));
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        cursor: pointer;
                        background-image: url('./assets/images/x.png');
                        &:hover{
                            background-image: url('./assets/images/x_hover.png');
                        }
                    }

                    &-iwatch{
                        width: var(--db-icon-w, calc(46px * var(--scale)));
                        height: var(--db-icon-h, calc(46px * var(--scale)));
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        cursor: pointer;
                        background-image: url('./assets/images/iwatch.png');
                        &:hover{
                            background-image: url('./assets/images/iwatch_hover.png');
                        }
                    }

                    &-switch{
                        width: var(--db-icon-w, calc(46px * var(--scale)));
                        height: var(--db-icon-h, calc(46px * var(--scale)));
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        cursor: pointer;
                        background-image: url('./assets/images/switch.png');
                        cursor: unset;
                    }
                }
            }
            &-pp {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--p9-r-l-g, calc(2px * var(--scale)));
                align-self: stretch;
                &-text {
                    align-self: stretch;
                    color: var(--base-black, #000);
                    font-family: "Afacad Flux";
                    font-size: calc(24px * var(--scale));
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    cursor: pointer;
                }
            }
        }
        &-right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--p9-r-l-g, calc(30px * var(--scale)));
            flex: 1 0 0;
            height: calc(276px * var(--scale));
            &-1 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--zck-font, calc(30px * var(--scale)));
                flex: 1 0 0;
                &-text1 {
                    align-self: stretch;
                    color: var(--base-black, #000);
                    font-family: "Afacad Flux";
                    font-size: calc(24px * var(--scale));
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                &-text2 {
                    align-self: stretch;
                    color: var(--base-black, #000);
                    font-family: "Afacad Flux";
                    font-size: calc(24px * var(--scale));
                    font-style: normal;
                    font-weight: 300;
                    line-height: calc(28px * var(--scale)); /* 116.667% */
                    white-space: normal;
                }
            }
            &-2 {
                display: flex;
                align-items: center;
                gap: var(--db-i-g, calc(18px * var(--scale)));
                align-self: stretch;
                &-text1 {
                    color: var(--base-black, #000);
                    font-family: "Afacad Flux";
                    font-size: calc(24px * var(--scale));
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                &-icon {
                    width: var(--db-icon-w, calc(46px * var(--scale)));
                    height: var(--db-icon-h, calc(46px * var(--scale)));
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    cursor: pointer;
                    background-image: url('./assets/images/x.png');
                    &:hover{
                        background-image: url('./assets/images/x_hover.png');
                    }
                }
            }
            &-3 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                &-email {
                    display: flex;
                    align-items: center;
                    gap: var(--zck-font, calc(30px * var(--scale)));
                    align-self: stretch;
                    &-placeholder {
                        flex: 1 0 0;
                        color: var(--base-black, #000);
                        font-family: "Afacad Flux";
                        font-size: calc(24px * var(--scale));
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

.follow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__input {
        &-wrapper {
            display: flex;
            align-items: center;
            gap: 30px;
            align-self: stretch;
            border-bottom: 0.6px solid #000;
        }
        flex: 1 0 0;
        color: #000;
        font-family: "Afacad Flux";
        font-size: calc(24px * var(--scale));
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border: 0px;
        outline: none;
    }
    &__btn {
        &-wrapper {
            display: flex;
            height: calc(20px * var(--scale));
            min-width: calc(36px * var(--scale));
            padding: calc(2px * var(--scale)) calc(8px * var(--scale));
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            border-radius: 2px;
            border: 0.552px solid #000;
        }
        align-self: stretch;
        color: #000;
        text-align: center;
        font-family: "Afacad Flux";
        font-size: calc(14px * var(--scale));
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

.followed {
    display: flex;
    width: calc(384px * var(--scale));
    height: calc(22px * var(--scale));
    padding: 0 calc(10px * var(--scale));
    justify-content: center;
    align-items: center;
    gap: calc(10px * var(--scale));
    border-radius: 4px;
    border: 0.6px solid #000;
    &__text {
        color: #000;
        font-family: "Afacad Flux";
        font-size: calc(14px * var(--scale));
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

.footerpPP {
    font-size: calc(18px * var(--scale));
}
.footerpFollowTitle {
    font-size: calc(20px * var(--scale));
}
.footerpFollowDesc {
    font-size: calc(18px * var(--scale));
}
.footerpFollowMe {
    font-size: calc(20px * var(--scale));
}
.footerpPlaceholder {
    font-size: calc(18px * var(--scale));
}
.footerpBtn {
    font-size: calc(14px * var(--scale));
}