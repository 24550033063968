.homepage{
    height: 100%;
    overflow: hidden;
    
    &-wrapper {
        // transition: transform .6s ease-in-out
    }

    &__header{
        opacity: 1;
        &_hidden{
            opacity: 0;
        }
        transition: opacity var(--ani-delay);
    }
}

