@import '../functions';

.p1{
    display: flex;
    // margin-top: px2vw(94);
    // height: calc((100vh - px2vw(94))); 
    height: 100vh;
    width: 100%;
    background-image: url('./assets/images/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center;
    position: relative;
    &__video {
        &-wrapper {
            // display: flex;
            // align-items: center;
            // width: 100%;
            // height: 100%;
            // justify-content: center;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            z-index: -1;
        }
        width: 100%;
        height: auto;
        transition: opacity .6s, max-height .6s
    }

    &__play {
        &-wrapper {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            
            // 移动端浏览器点击会出现阴影
            -webkit-tap-highlight-color: transparent;
            outline: none;
            touch-action: manipulation;
        }
        width: px2vw(186);
        height: px2vw(286);
        flex-shrink: 0;
    }

    &__footer{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        display: flex;
        padding-bottom: px2vw(38);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: px2vw(46);
        &-zck{
            display: flex;
            padding-left: px2vw(42);
            align-items: center;
            gap: px2vw(16);
    
            border-radius: 50px;
            border: px2vw(1) solid #FFF;
            
            margin: auto;
            overflow: hidden;
    
            &-input{
                &-wrapper{
                    display: flex;
                    // width: 290px;
                    padding: 0px px2vw(16) px2vw(12) 0px;
                    flex-direction: column;
                    align-items: flex-start;
                    box-sizing: border-box;
                }
    
                outline: none;
                background: none;
                border: 0px;
    
                display: flex;
                padding: 0px px2vw(14) 0px px2vw(8);
                justify-content: center;
                align-items: center;
                align-self: stretch;
    
                border-bottom: px2vw(1) solid #FFF;
    
                color:#FFF;
                font-family: "Afacad Flux";
                font-size: px2vw(18);
                font-style: normal;
                font-weight: 300;
                line-height: normal;
    
                &::placeholder{
                    color: rgba(255, 255, 255, 0.50);
                }
            }
    
            &-btn{
                &-wrapper{
                    display: flex;
                    width: px2vw(194);
                    height: px2vw(60);
                    justify-content: center;
                    align-items: center;
                }
                display: flex;
                height: px2vw(60);
                width: px2vw(110);
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
    
                border-radius: 50px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                background: linear-gradient(120deg, rgba(219, 210, 220, 0.80) -2.29%, rgba(176, 128, 175, 0.80) 60.1%);
    
                color: #FFF;
                font-family: "Afacad Flux";
                font-size: px2vw(15);
                font-style: normal;
                font-weight: 300;
                line-height: normal;
    
            }
        }
    
        &-icons{
            display: flex;
            align-items: center;
            gap: px2vw(18);
            &-icon{
                width: px2vw(46);
                height: px2vw(46);
                &_steam{
                    background: url('./assets/images/icon/steam.png');
                    background-size: 100%;
                }
        
                &_x{
                    background: url('./assets/images/icon/x.png');
                    background-size: 100%;
                }
        
                &_mail{
                    background: url('./assets/images/icon/mail.png');
                    background-size: 100%;
                }
        
                &_iwatch{
                    background: url('./assets/images/icon/iwatch.png');
                    background-size: 100%;
                }
        
                &_switch{
                    background: url('./assets/images/icon/switch.png');
                    background-size: 100%;
                }
            }
        }
    
    }

}


.zck{
    display: flex;
    padding-left: px2vw(26);
    align-items: center;
    gap: px2vw(12);
    width: px2vw(602);
    height: px2vw(88);
    box-sizing: border-box;

    // border-image: linear-gradient(175deg, rgba(186, 160, 173, 1), rgba(255, 255, 255, 1)) 1 1;
    border-radius: 50px;
    border: px2vw(1) solid #FFF;
    background: rgba(255, 255, 255, 0.16);

    // overflow: hidden;
    position: relative;

    opacity: 0;
    &_show{
        opacity: 1;
    }    
    transition: opacity .3s;

    &__input{
        flex: 1;
        display: flex;
        align-items: center;
        gap: px2vw(10);
        width: px2vw(344);
        transition: transform .3s, opacity .3s;
        opacity: 1;
        &_hidden{
            opacity: 0;
        }

        &__country{
            &-wrapper{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-bottom: px2vw(1) solid white;
                box-sizing: border-box;
                // display: none;
                opacity: 1;
                transition: opacity .3s;

                // &__inputing{
                //     display: block;
                // }
            }

            display: flex;
            padding: 0 px2vw(14);
            justify-content: center;
            align-items: center;     
            
            color: #FFF;
            font-family: "Afacad Flux";
            font-size: calc(24px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            // padding-bottom: px2vw(5);
            height: px2vw(46);
        }

        &__text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            &-wrapper{
                display: flex;
                width: 100%;
                padding: 0px px2vw(16) 0 0px;
                flex-direction: column;
                align-items: flex-start;

                border-bottom: px2vw(1) solid white;
                box-sizing: border-box;

                &__inputing{
                    // display: none;
                }
            }
    
            width: 100%;
            outline: none;
            background: none;
            border: 0px;
    
            display: flex;
            // padding: 0px var(--zck-input-p-r, calc(10px * var(--scale))) 0px var(--zck-input-p-l, calc(6px * var(--scale)));
            justify-content: center;
            align-items: center;
            align-self: stretch;
    
            // border-bottom: 1px solid #FFF;
    
            color:#FFF;
            font-family: "Afacad Flux";
            font-size: calc(24px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 300;
            line-height: normal;
    
            &::placeholder{
                color: rgba(255, 255, 255, 0.50);
            }
        }
        
    }

    &__block{
        width: px2vw(260);
        height: px2vw(90);
    }

    &__btn{
        &-wrapper{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            max-width: px2vw(260);
            transition: max-width .3s;
        }
        display: flex;
        width: 100%;
        padding: 0px px2vw(36);
        // height: px2vw(90);
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        border-radius: 50px;
        background: linear-gradient(120deg, rgba(219, 210, 220, 0.80) -2.29%, rgba(176, 128, 175, 0.80) 60.1%);

        color: #FFF;
        font-family: "Afacad Flux";
        font-size: calc(28px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;

    }
}

.region-selection{
    position: relative;
    display: inline-block;
    
    &__ul{
        display: flex;
        width: px2vw(366);
        padding: px2vw(10) 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: px2vw(20);

        opacity: 0.8;
        background: linear-gradient(90deg, #D9D0DA 0%, #AE80AD 100%);

        overflow-y: scroll;
        scrollbar-width: none;
        height: px2vw(399);
        box-sizing: border-box;


        &-wrapper{
            position: absolute;
            left: 0;
            bottom: 0;
            // max-width: calc(441px * var(--scale));
            // opacity: 1;
            overflow: hidden;
            &_hidden{
                max-width: 0;
                // opacity: 0;
            }
        }
    }

    &__li{
        display: flex;
        // height: px2vw(37);
        padding: 0px px2vw(20) 0px px2vw(38);
        align-items: center;
        gap: px2vw(20);
        align-self: stretch;

        box-sizing: border-box;
        cursor: pointer;

        color: #FFF;
        font-family: "Afacad Flux";
        font-size: calc(28px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 300;

        &_select{
            font-weight: 600;
        }

        &_select,
        &:hover{
            background: rgba(255, 255, 255, 0.20);
        }

        &__phone{
            width: px2vw(72);
        }

        &__country{
            flex: 1;   
        }
    }
}

.btn-fr {
    font-size: calc(20px * var(--fontScale, .5));
    line-height: px2vw(24);
}
.btn-ru {
    font-size: calc(20px * var(--fontScale, .5));
    line-height: normal;
}

.input-ru {
    font-size: calc(20px * var(--fontScale, .5));
}
.input-pt {
    font-size: calc(20px * var(--fontScale, .5));
}
.input-es {
    font-size: calc(20px * var(--fontScale, .5));
}
.input-fr {
    font-size: calc(22px * var(--fontScale, .5));
}
.input-it {
    font-size: calc(22px * var(--fontScale, .5));
}


/* 尝试不同的选择器 */
video::-webkit-media-controls-download-button,
video::-internal-media-controls-download-button,
video::-moz-controls .downloads-button,
video::-moz-controls download-button,
video::-ms-controls-download-button {
  display: none !important;
}

/* 针对 WebKit 浏览器 */
video::-webkit-media-controls-enclosure {
  overflow: hidden;
}
video::-webkit-media-controls-panel {
  display: flex !important;
  justify-content: center;
  align-items: center;
}