
@import '../functions';

.privacyPolicy{
    margin-top: px2vw(94);
    display: flex;
    padding: 0 px2vw(30);
    flex-direction: column;
    align-items: flex-start;
    gap: px2vw(30);
    align-self: stretch;

    &-1 {
        display: flex;
        padding: px2vw(78) 0 px2vw(30) 0;
        flex-direction: column;
        align-items: flex-start;
        gap: px2vw(18);
        align-self: stretch;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);

        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            span {
                align-self: stretch;
                color: #000;
                font-family: "Afacad Flux";
                font-size: calc(28px * var(--fontScale, .5));
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        span {
            align-self: stretch;
            color: #000;
            font-family: "Afacad Flux";
            font-size: calc(24px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 300;
            line-height: px2vw(28); /* 116.667% */
        }
    }
    &-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: px2vw(12);
        align-self: stretch;

        &-title {
            display: flex;
            padding: px2vw(8) 0px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            &-text {
                flex: 1 0 0;
                color: #000;
                font-family: "Afacad Flux";
                font-size: calc(28px * var(--fontScale, .5));
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        &-content {
            display: flex;
            padding-top: 8px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            &-text {
                flex: 1 0 0;
                color: #000;
                font-family: "Afacad Flux";
                font-size: calc(24px * var(--fontScale, .5));
                font-style: normal;
                font-weight: 300;
                line-height: px2vw(28); /* 116.667% */
            }
        }
        li {
            display: flex;
            padding-left: px2vw(32);
            justify-content: center;
            align-items: center;
            gap: px2vw(10);
            align-self: stretch;
        }
        // span {
        //     display: flex;
        //     padding: px2vw(8) px2vw(78);
        //     justify-content: center;
        //     align-items: center;
        //     align-self: stretch;
        //     font-family: "Afacad Flux";
        //     font-size: calc(24px * var(--fontScale, .5));
        //     font-style: normal;
        //     font-weight: 300;
        //     line-height: px2vw(28); /* 116.667% */
        // }
        &-list {
            position: relative;
            padding-left: px2vw(100);
            font-family: "Afacad Flux";
            font-size: calc(24px * var(--fontScale, .5));
            font-style: normal;
            font-weight: 300;
            line-height: px2vw(28); /* 116.667% */
            div:before {
                content: "•";
                position: absolute;
                left: px2vw(80);
            }
        }
    }
    &-3 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: px2vw(12);
        align-self: stretch;
        &-title {
            display: flex;
            padding: px2vw(8) 0px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            &-text{
                flex: 1 0 0;
                color: #000;
                font-family: "Afacad Flux";
                font-size: calc(28px * var(--fontScale, .5));
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: px2vw(24);
            align-self: stretch;
            &-1 {
                display: flex;
                padding-top: px2vw(8);
                justify-content: center;
                align-items: center;
                align-self: stretch;
                &-text {
                    flex: 1 0 0;
                    color: #000;
                    font-family: "Afacad Flux";
                    font-size: calc(24px * var(--fontScale, .5));
                    font-style: normal;
                    font-weight: 300;
                    line-height: px2vw(28); /* 116.667% */
                }
            }
            &-2 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
            }
            &-3 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                &-panel {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: -2px;
                    align-self: stretch;
                    &-t {
                        color: #000;
                        font-family: "Afacad Flux";
                        font-size: calc(24px * var(--fontScale, .5));
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    &-c {
                        align-self: stretch;
                        color: #000;
                        font-family: "Afacad Flux";
                        font-size: calc(24px * var(--fontScale, .5));
                        font-style: normal;
                        font-weight: 300;
                        line-height: px2vw(28); /* 116.667% */
                    }
                }
            }
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    &-title {
        color: #000;
        font-family: "Afacad Flux";
        font-size: calc(24px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    &-text {
        align-self: stretch;
        color: #000;
        font-family: "Afacad Flux";
        font-size: calc(24px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 300;
        line-height: px2vw(28); /* 116.667% */
    }
    &-list {
        position: relative;
        padding-left: px2vw(20);
        font-family: "Afacad Flux";
        font-size: calc(24px * var(--fontScale, .5));
        font-style: normal;
        font-weight: 300;
        line-height: px2vw(28); /* 116.667% */
        div:before {
            content: "•";
            position: absolute;
            left: px2vw(10);
        }
    }
}